import MeowBanner from "assets/images/meow_banner.webp";
import MeowBanner2 from "assets/images/meow_banner_2.webp";
import MetafireBanner from "assets/images/metafire_banner.webp";
import MetafireBanner2 from "assets/images/metafire_banner_2.webp";
import PlantEmpires1 from "assets/images/plant_empires_1.webp";
import PlantEmpires from "assets/images/plant_empires.webp";
import GameBg from "assets/images/game_bg.webp";
import { ButtonSecondary } from "components/Button";
import EffectCarousel from "effect-carousel.esm";
import { motion } from "framer-motion";
import { ViewPort } from "hooks/useBatchRef";
import { Flex } from "rebass";
import styled from "styled-components/macro";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionLabel } from "../styles";

export default function Game({ GameViewPort }: { GameViewPort: ViewPort }) {
  return (
    <>
      <div ref={GameViewPort.ref}></div>
      <Wrapper>
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ y: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Flex justifyContent="space-between">
              <SectionLabel>Games</SectionLabel>
              <ButtonSecondary
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_GAME_GUIDE_URL}tournament`,
                    "_blank"
                  );
                }}
                style={{
                  height: "fit-content",
                  margin: "48px 0",
                }}
              >
                See All
              </ButtonSecondary>
            </Flex>
          </motion.div>
        </Container>
        <Swiper
          modules={[Autoplay, Navigation, Pagination, EffectCarousel]}
          effect={"carousel" as any}
          grabCursor={true}
          loop={true}
          slidesPerView="auto"
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
          }}
          autoplay={{
            delay: 3000,
          }}
        >
          <SwiperSlide>
            <div
              className="swiper-carousel-animate-opacity"
              onClick={() => {
                window.open("https://plantempires.io/", "_blank");
              }}
            >
              <img src={PlantEmpires} alt="" />
              <div className="slide-content">
                <p className="title">Plant Empires</p>
                <p>
                  Grow your plants, build your army, and survive the deadly
                  attacks from The Walking Dead
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-animate-opacity">
              <img src={MetafireBanner} alt="" />
              <div className="slide-content">
                <p className="title">MetaFire</p>
                <p>
                  Metafire is a blockchain-powered Metaverse project which
                  displayed as Real-time battle royale game.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-animate-opacity">
              <img src={MeowBanner} alt="" />
              <div className="slide-content">
                <p className="title">Meow</p>
                <p>
                  Get ready to scratch and claw your way to the top in Meow -
                  Cat Fighter!
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="swiper-carousel-animate-opacity"
              onClick={() => {
                window.open("https://plantempires.io/", "_blank");
              }}
            >
              <img src={PlantEmpires1} alt="" />
              <div className="slide-content">
                <p className="title">Plant Empires</p>
                <p>
                  Grow your plants, build your army, and survive the deadly
                  attacks from The Walking Dead
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-animate-opacity">
              <img src={MetafireBanner2} alt="" />
              <div className="slide-content">
                <p className="title">MetaFire</p>
                <p>
                  When playing Metafire, players will experience the latest
                  blockchain battle Royale game genre, with moments of dramatic
                  competition, fierce combat and breaking down barriers between
                  blockchain and traditional games
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-carousel-animate-opacity">
              <img src={MeowBanner2} alt="" />
              <div className="slide-content">
                <p className="title">Meow</p>
                <p>
                  Inspired by the Japanese Maneki Neko, this action-packed
                  mobile game lets you take on other cats in an epic battle for
                  dominance. Use your wits and reflexes to push opponents off
                  the map and claim victory, all while growing bigger and
                  stronger with each round.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Wrapper>
    </>
  );
}

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
  min-width: 100vh;
  @media screen and (max-width: 1000px) {
    min-width: 100%;
    padding: 0 16px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 80px 16px;
  background: url(${GameBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(100px);
  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;
