import { Flex, Image } from "rebass";
import DggLogoBig from "assets/svg/dgg_network_big.svg";
import styled, { css } from "styled-components/macro";
import { RefObject, useEffect, useState } from "react";
import { ButtonPrimary } from "components/Button";
import { ViewPort } from "hooks/useBatchRef";
import { useScreenWidth } from "hooks/useScreenWith";
import Nav from "components/Icons/Nav";
import MobileNav from "./MobileNav";
import ContactModal from "components/ContactModal";
import ProductsNavGroup from "./groups/ProductsNavGroup";

export const StyledLink = styled.div<{ active: boolean }>`
  border-bottom: ${({ theme, active }) =>
    active ? `2px solid ${theme.primary}` : "2px solid transparent"};
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.primary};
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export default function Header({
  BannerViewPort,
  AboutUsViewPort,
  GameViewPort,
  TeamsAndPartnersViewPort,
  ProductsViewPort,
  SolutionsViewPort,
  scrollToSection,
}: {
  BannerViewPort: ViewPort;
  AboutUsViewPort: ViewPort;
  GameViewPort: ViewPort;
  TeamsAndPartnersViewPort: ViewPort;
  ProductsViewPort: ViewPort;
  SolutionsViewPort: ViewPort;
  scrollToSection: (elementRef: RefObject<HTMLDivElement | null>) => void;
}) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const { upToMedium } = useScreenWidth();

  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  const toggleOpenMobileNav = () => {
    setIsOpenMobileNav(!isOpenMobileNav);
  };

  return (
    <HeaderWrapper scrolled={scrolled}>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Image
          src={DggLogoBig}
          height={100}
          alt="DGG NetWork"
          onClick={() => scrollToSection(BannerViewPort.pureRef)}
          style={{
            cursor: "pointer",
            padding: "20px 40px",
          }}
        />
        <Flex alignItems="center" style={{ gap: 35 }}>
          <StyledLink
            active={false}
            onClick={() => scrollToSection(BannerViewPort.pureRef)}
          >
            Home
          </StyledLink>
          <StyledLink
            active={false}
            onClick={() => scrollToSection(AboutUsViewPort.pureRef)}
          >
            About Us
          </StyledLink>
          <StyledLink
            active={false}
            onClick={() => scrollToSection(GameViewPort.pureRef)}
          >
            Games
          </StyledLink>
          <StyledLink
            active={false}
            onClick={() => scrollToSection(TeamsAndPartnersViewPort.pureRef)}
          >
            Partners
          </StyledLink>
          <StyledLink
            active={false}
            onClick={() => scrollToSection(SolutionsViewPort.pureRef)}
          >
            Solution
          </StyledLink>
          <ProductsNavGroup />

          <StyledLink
            active={false}
            onClick={() => {
              window.open("https://docs.dgg.network/introduction/whitepaper");
            }}
          >
            Whitepaper
          </StyledLink>
          <ContactModal>
            <ContactButton>Contact Us</ContactButton>
          </ContactModal>

          {upToMedium && (
            <Nav
              onClick={toggleOpenMobileNav}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </Flex>
      </Flex>
      <MobileNav
        isOpenMobileNav={isOpenMobileNav}
        setIsOpenMobileNav={setIsOpenMobileNav}
        toggleOpenMobileNav={toggleOpenMobileNav}
        BannerViewPort={BannerViewPort}
        AboutUsViewPort={AboutUsViewPort}
        GameViewPort={GameViewPort}
        TeamsAndPartnersViewPort={TeamsAndPartnersViewPort}
        ProductsViewPort={ProductsViewPort}
        SolutionsViewPort={SolutionsViewPort}
        scrollToSection={scrollToSection}
      />
    </HeaderWrapper>
  );
}

const ContactButton = styled(ButtonPrimary)`
  @media screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const HeaderWrapper = styled.div<{ scrolled: boolean }>`
  width: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  transition: 0.2s ease-in-out;
  padding: 20px 10px;
  ${({ scrolled }) =>
    scrolled &&
    css`
      padding: 0 6px;
      background-color: rgba(0, 0, 0, 0.7);
    `}
`;
