import styled from "styled-components/macro";

export const Button = styled.button`
  border-radius: 6px;
  padding: 16px 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 16px 24px;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textBlack};
  border: 1px solid ${({ theme }) => theme.primary};
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    box-shadow: rgba(0, 255, 255, 0.7) 0px 0px 16px;
  }
`;

export const ButtonSecondary = styled(Button)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.secondary};
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.secondary};
  }
`;
