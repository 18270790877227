import React from "react";

function Nav(props: any) {
  return (
    <svg
      width={28}
      height={25}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.6 3.2C.6 1.874 1.842.8 3.373.8h15.254C20.158.8 21.4 1.875 21.4 3.2s-1.242 2.4-2.773 2.4H3.373C1.842 5.6.6 4.525.6 3.2z"
        fill="#0FF"
        fillOpacity={0.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.6 12.8c0-1.325 1.218-2.4 2.72-2.4h21.76c1.502 0 2.72 1.075 2.72 2.4s-1.218 2.4-2.72 2.4H3.32C1.818 15.2.6 14.126.6 12.8zM.6 22.4c0-1.325 1.313-2.4 2.933-2.4h11.734c1.62 0 2.933 1.075 2.933 2.4 0 1.326-1.313 2.4-2.933 2.4H3.533C1.913 24.8.6 23.725.6 22.4z"
        fill="#0FF"
      />
    </svg>
  );
}

export default Nav;
