import React, { RefObject } from "react";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components/macro";
import DggNetwork from "assets/svg/dgg_network_small.svg";
import Discord from "components/Icons/Discord";
import Facebook from "components/Icons/Facebook";
import Telegram from "components/Icons/Telegram";
import Twitter from "components/Icons/Twitter";
import { ViewPort } from "hooks/useBatchRef";
import Background from "assets/svg/planet_footer_right.svg";
import { StoreWrapper } from "components/Header/groups/ProductsNavGroup";
import AppleStore from "assets/svg/applestore.svg";
import GooglePlay from "assets/svg/googleplay.svg";
import PoweredByMoralis from "assets/images/Powered-by-Moralis.png";
import useTheme from "hooks/useTheme";
import { useScreenWidth } from "hooks/useScreenWith";

export default function Footer({
  BannerViewPort,
  AboutUsViewPort,
  GameViewPort,
  TeamsAndPartnersViewPort,
  ProductsViewPort,
  SolutionsViewPort,
  scrollToSection,
}: {
  BannerViewPort: ViewPort;
  AboutUsViewPort: ViewPort;
  GameViewPort: ViewPort;
  TeamsAndPartnersViewPort: ViewPort;
  ProductsViewPort: ViewPort;
  SolutionsViewPort: ViewPort;
  scrollToSection: (elementRef: RefObject<HTMLDivElement | null>) => void;
}) {
  const theme = useTheme();
  const { upToSmall } = useScreenWidth();
  return (
    <Wrapper>
      <Container>
        <Layout>
          <Flex
            flexDirection="column"
            style={{
              paddingLeft: 12,
            }}
          >
            <Logo>
              <Image height="120%" src={DggNetwork} alt="Dgg logo" />
            </Logo>
            <Text>
              <p>The Leading Game Publisher and Incubator</p>
              <p>Established to boost million users for NFT Games globally.</p>
            </Text>
          </Flex>
          <LayoutLeft>
            <Flex
              flexDirection="column"
              style={{
                paddingLeft: 12,
              }}
            >
              <StyledLink
                onClick={() => scrollToSection(BannerViewPort.pureRef)}
              >
                Home
              </StyledLink>
              <StyledLink
                onClick={() => scrollToSection(AboutUsViewPort.pureRef)}
              >
                About Us
              </StyledLink>
              <StyledLink onClick={() => scrollToSection(GameViewPort.pureRef)}>
                Games
              </StyledLink>
              <StyledLink
                action=""
                onClick={() =>
                  scrollToSection(TeamsAndPartnersViewPort.pureRef)
                }
              >
                Partners
              </StyledLink>
              <StyledLink
                onClick={() => scrollToSection(SolutionsViewPort.pureRef)}
              >
                Solution
              </StyledLink>
              <StyledLink
                onClick={() => scrollToSection(ProductsViewPort.pureRef)}
              >
                Product
              </StyledLink>

              <StyledLink
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper",
                    "_blank"
                  );
                }}
              >
                Whitepaper
              </StyledLink>
              <StyledLink
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/help-and-feedback/privacy-policy",
                    "_blank"
                  );
                }}
              >
                Privacy Policy
              </StyledLink>
            </Flex>

            <Flex flexDirection="column" justifyContent="space-between">
              <Flex
                alignItems="flex-start"
                style={{
                  height: "40px",
                }}
              >
                <Text
                  fontSize={20}
                  color={theme.text}
                  style={{
                    cursor: "default",
                    paddingLeft: 12,
                  }}
                  fontWeight={700}
                >
                  DGG Wallet
                </Text>
              </Flex>
              <WalletWrapper>
                <StoreWrapper
                  href={process.env.REACT_APP_DGG_WALLET_APPLE_STORE_URL}
                  target="_blank"
                >
                  <StoreImage src={AppleStore} alt="Apple Store"></StoreImage>
                </StoreWrapper>
                <StoreWrapper
                  href={process.env.REACT_APP_DGG_WALLET_GOOGLE_PLAY_URL}
                  target="_blank"
                >
                  <StoreImage src={GooglePlay} alt="Google Play"></StoreImage>
                </StoreWrapper>
              </WalletWrapper>

              <Social>
                <SocialLink
                  href="https://t.me/dgg_network"
                  target="_blank"
                  aria-label="telegram"
                >
                  <Telegram />
                </SocialLink>
                <SocialLink
                  href="https://twitter.com/DggNetwork"
                  target="_blank"
                  aria-label="twitter"
                >
                  <Twitter />
                </SocialLink>
                <SocialLink
                  href="https://discord.com/invite/KnfTAmydgK"
                  target="_blank"
                  aria-label="discord"
                >
                  <Discord />
                </SocialLink>
                <SocialLink
                  href="https://www.facebook.com/dggnetwork"
                  target="_blank"
                  aria-label="facebook"
                >
                  <Facebook />
                </SocialLink>
              </Social>

              <StoreWrapper
                href={process.env.REACT_APP_DGG_WALLET_MORALIS_URL}
                target="_blank"
              >
                <PoweredImage
                  src={PoweredByMoralis}
                  alt="Powered By Moralis"
                ></PoweredImage>
              </StoreWrapper>
            </Flex>
          </LayoutLeft>
        </Layout>
      </Container>
      <Flex
        justifyContent="center"
        style={{
          marginTop: "20px",
          borderTop: "0.2px solid #fff",
          paddingTop: "20px",
        }}
      >
        <Text fontSize={upToSmall ? 12 : 16}>
          Copyright ® 2022 DGG Network. All rights Reserved
        </Text>
      </Flex>
    </Wrapper>
  );
}

const LayoutLeft = styled(Flex)`
  justify-content: space-between;
  gap: 200px;
  @media screen and (max-width: 1100px) {
    gap: 100px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const StoreImage = styled(Image)`
  border-radius: 16px;
  width: 180px;
  height: 180px;
`;

const PoweredImage = styled(Image)`
  border-radius: 16px;
  width: 200px;
  height: 180px;
`;

const WalletWrapper = styled(Flex)`
  gap: 16px;
`;

const Wrapper = styled.div`
  background: #000000;
  padding: 20px 0;
  width: 100%;
  border-top: 0.2px solid #d8d8d8;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto;
  @media screen and (max-width: 768px) {
    background-size: cover;
  }
`;

const Layout = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media screen and (max-width: 988px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    padding: 0px 32px;
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
`;

const Logo = styled.div`
  height: 200px;
`;
const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledLink = styled(Text)`
  cursor: pointer;
  padding-bottom: 8px;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;
