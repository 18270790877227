import { ViewPort } from "hooks/useBatchRef";
import { Flex, Text, Image } from "rebass";
import styled, { keyframes } from "styled-components/macro";
import HeroBg from "assets/images/hero.webp";
import HeroImg from "assets/images/hero_image.webp";
import useTheme from "hooks/useTheme";
import { ButtonPrimary } from "components/Button";
import { updown, Planet1Animation, Planet2Animation } from "../styles";
import Planet1 from "assets/svg/planet_1.svg";
import Planet2 from "assets/svg/planet_2.svg";
import AppleStore from "assets/svg/applestore.svg";
import GooglePlay from "assets/svg/googleplay.svg";
import { useScreenWidth } from "hooks/useScreenWith";

export default function Banner({
  BannerViewPort,
}: {
  BannerViewPort: ViewPort;
}) {
  const theme = useTheme();
  const { upToSmall } = useScreenWidth();
  return (
    <Wrapper>
      <div ref={BannerViewPort.ref}></div>
      <Container>
        <Flex flexDirection="column">
          <Layout>
            <StyledDescription>
              <HeroText>DGG Network</HeroText>
              <div>
                <Text
                  color={theme.text}
                  marginBottom={16}
                  fontSize={20}
                  fontWeight={700}
                  as="h1"
                >
                  The Leading Game Publisher and Incubator
                </Text>
                <Text color={theme.text} fontSize={20} fontWeight={600} as="h2">
                  Established to boost million users for NFT Games globally.
                </Text>
              </div>

              <Planet1Image
                height={200}
                width={200}
                src={Planet1}
                alt="planet 1"
              />
              <Planet2Image src={Planet2} alt="planet 2" />
            </StyledDescription>

            <HeroImage src={HeroImg} alt="hero image" />
          </Layout>
          <ListButtonWrapper>
            <ButtonPrimary
              style={{
                width: upToSmall ? "100%" : "fit-content",
                fontSize: upToSmall ? "18px" : "16px",
              }}
              onClick={() => {
                window.open("https://docs.dgg.network/", "_blank");
              }}
            >
              Get more details
            </ButtonPrimary>
            <ListStoreButtonWrapper>
              <StoreWrapper
                href={process.env.REACT_APP_DGG_WALLET_APPLE_STORE_URL}
                target="_blank"
              >
                <StoreImage src={AppleStore} alt="Apple Store"></StoreImage>
              </StoreWrapper>
              <StoreWrapper
                href={process.env.REACT_APP_DGG_WALLET_GOOGLE_PLAY_URL}
                target="_blank"
              >
                <StoreImage src={GooglePlay} alt="Google Play"></StoreImage>
              </StoreWrapper>
            </ListStoreButtonWrapper>
          </ListButtonWrapper>
        </Flex>
      </Container>
    </Wrapper>
  );
}

const StoreWrapper = styled.a`
  cursor: pointer;
  height: fit-content;
  @media screen and (max-width: 768px) {
    width: 100%;
    background-color: black;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }
`;

const StoreImage = styled(Image)`
  border-radius: 8px;
  width: 200px;
  @media screen and (max-width: 768px) {
    width: 218px;
  }
`;

const Planet1Image = styled(Image)`
  position: absolute;
  top: -30%;
  animation: ${Planet1Animation} 3s linear infinite;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Planet2Image = styled(Image)`
  position: absolute;
  bottom: -20%;
  right: -30%;
  width: 300px;
  height: 300px;
  animation: ${Planet2Animation} 3s linear infinite;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const StyledDescription = styled(Flex)`
  flex-direction: column;
  gap: 96px;
  position: relative;
  @media screen and (max-width: 988px) {
    gap: 30px;
  }
`;

const shine = keyframes`
  0% {
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  100% {
    background-position: 500px;
  }
`;

const HeroText = styled(Text)`
  font-size: 75px;
  font-weight: 700;
  white-space: nowrap;
  /* text-shadow: -8px 10px 1px rgba(0, 255, 255, 0.5); */
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 40px;
  }
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.text} 0,
    ${({ theme }) => theme.primary} 10%,
    ${({ theme }) => theme.text} 20%
  );
  animation: ${shine} 1.32s infinite linear;
  animation-fill-mode: forwards;
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-size-adjust: none;
`;

const HeroImage = styled(Image)`
  animation: ${updown} 3s linear infinite;
  @media screen and (min-width: 768px) {
    max-width: 550px !important;
  }
`;

const Layout = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 988px) {
    flex-direction: column-reverse;
    gap: 68px;
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  width: 100%;
  padding: 200px 16px;
  @media screen and (max-width: 988px) {
    padding: 138px 16px;
    padding-bottom: 50px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-image: url(${HeroBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ListButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 32px;
    gap: 16px;
  }
`;

const ListStoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  width: 100%;
  gap: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;
