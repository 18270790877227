import { ReactNode, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Text } from "rebass";
import styled from "styled-components";

import { ReactComponent as DropdownSVG } from "assets/svg/down.svg";
import useTheme from "hooks/useTheme";

const Wrapper = styled.div`
  transition: all 0.2s ease;
  overflow: hidden;
  flex: 1;
`;
const LinkContainer = styled.div<{ isShow?: boolean; height: number }>`
  padding-left: 12px;
  transition: all 0.3s ease;
  ${({ isShow, height }) =>
    isShow ? `height: ${height}px;` : "height: 0px;"}
  > * {
    padding: 12px 0;
  }

  > *:first-child {
    padding-top: 24px;
  }
  > *:last-child {
    padding-bottom: 0;
  }
`;
const DropdownIcon = styled(DropdownSVG)<{ isshow: string }>`
  transition: all 0.2s ease;
  height: 24px !important;
  width: 24px !important;
  ${({ isshow }) => isshow === "true" && "transform: rotate(180deg);"}
`;

const TitleWrapper = styled(NavLink)`
  text-decoration: none;
`;

export default function NavDropDown({
  title,
  link,
  icon,
  options,
  toggle,
  children,
}: {
  title: string;
  icon?: ReactNode;
  link: string;
  options: {
    link: string;
    label: string;
    external?: boolean;
    target?: string;
    commingsoon?: boolean;
  }[];
  toggle: () => void;
  children: ReactNode;
}) {
  const [isShowOptions, setIsShowOptions] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsShowOptions((prev) => !prev);
  };

  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  return (
    <Wrapper>
      <TitleWrapper to={link} onClick={handleClick}>
        {/* {icon} */}
        <Text flex={1}>{title}</Text>
        <DropdownIcon isshow={isShowOptions.toString()} />
      </TitleWrapper>
      <LinkContainer
        isShow={isShowOptions}
        ref={ref}
        height={ref.current?.scrollHeight || 0}
      >
        {options.map((item) =>
          item.external ? (
            <StyledNavExternalLinkMobile
              key={item.link}
              href={item.link}
              onClick={toggle}
              target={item.target}
              comingsoon={item.commingsoon}
            >
              {item.label}
              {item.commingsoon && (
                <Text
                  style={{
                    color: theme.primary,
                    padding: "0px 12px",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  (Coming Soon)
                </Text>
              )}
            </StyledNavExternalLinkMobile>
          ) : (
            <NavLink to={item.link} key={item.link} onClick={toggle}>
              {item.label}
            </NavLink>
          )
        )}
        {children}
      </LinkContainer>
    </Wrapper>
  );
}

const StyledNavExternalLinkMobile = styled.a<{
  active?: boolean;
  comingsoon?: boolean;
}>`
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: ${({ comingsoon }) => (comingsoon ? "default" : "pointer")};
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  width: fit-content;
  padding: 8px 0px;
  font-weight: 600;
`;
