import AppleStore from "assets/svg/applestore.svg";
import GooglePlay from "assets/svg/googleplay.svg";
import DggLogo from "assets/svg/logo_with_text.svg";
import CloseDialogIcon from "components/Icons/Close";
import { motion } from "framer-motion";
import { ViewPort } from "hooks/useBatchRef";
import useTheme from "hooks/useTheme";
import { rgba } from "polished";
import { RefObject, useEffect, useRef } from "react";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components/macro";
import { WalletWrapper } from "./groups/ProductsNavGroup";
import NavDropDown from "./NavDropDown";

export default function MobileNav({
  isOpenMobileNav,
  setIsOpenMobileNav,
  toggleOpenMobileNav,
  BannerViewPort,
  AboutUsViewPort,
  GameViewPort,
  TeamsAndPartnersViewPort,
  ProductsViewPort,
  SolutionsViewPort,
  scrollToSection,
}: {
  isOpenMobileNav: boolean;
  setIsOpenMobileNav: (isOpenMobileNav: boolean) => void;
  toggleOpenMobileNav: () => void;
  BannerViewPort: ViewPort;
  AboutUsViewPort: ViewPort;
  GameViewPort: ViewPort;
  TeamsAndPartnersViewPort: ViewPort;
  ProductsViewPort: ViewPort;
  SolutionsViewPort: ViewPort;
  scrollToSection: (elementRef: RefObject<HTMLDivElement | null>) => void;
}) {
  const node = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      // @ts-ignore
      if (node.current?.contains(e.target)) {
        return;
      }
      setIsOpenMobileNav(false);
    };
    if (isOpenMobileNav) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMobileNav, setIsOpenMobileNav]);

  const sidebar = {
    open: (height = 1200) => ({
      pointerEvents: "all",
      clipPath: `circle(${height * 2 + 200}px at 100% 0px)`,
      zIndex: 99,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      pointerEvents: "none",
      clipPath: "circle(0px at 100% 0px)",
      transition: {
        // delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const handleClick = (elementRef: RefObject<HTMLDivElement | null>) => {
    setIsOpenMobileNav(false);
    scrollToSection(elementRef);
  };

  const theme = useTheme();
  return (
    <div
      ref={node}
      style={{
        position: "relative",
      }}
    >
      <motion.div
        style={{
          width: "100%",
        }}
      >
        <motion.div
          initial={false}
          animate={isOpenMobileNav ? "open" : "closed"}
          // @ts-ignore
          variants={sidebar}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            transformOrigin: "50% -30px",
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
            background: theme.background,
          }}
        >
          <motion.div
            variants={variants}
            style={{
              height: "100%",
              marginTop: 0,
            }}
          >
            <Wrapper>
              <Flex flexDirection="column" style={{ gap: 50 }}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    borderBottom: `1px solid #626262`,
                  }}
                >
                  <Image
                    src={DggLogo}
                    alt="DGG NetWork"
                    onClick={() => scrollToSection(BannerViewPort.pureRef)}
                    style={{
                      cursor: "pointer",
                      minHeight: 70,
                      padding: 10,
                    }}
                  />
                  <CloseDialogIcon
                    onClick={toggleOpenMobileNav}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                alignItems="center"
                style={{ gap: 40, paddingTop: 40 }}
              >
                <StyledLink
                  active={false}
                  onClick={() => handleClick(BannerViewPort.pureRef)}
                >
                  Home
                </StyledLink>
                <StyledLink
                  active={false}
                  onClick={() => handleClick(AboutUsViewPort.pureRef)}
                >
                  About Us
                </StyledLink>
                <StyledLink
                  active={false}
                  onClick={() => handleClick(GameViewPort.pureRef)}
                >
                  Games
                </StyledLink>
                <StyledLink
                  active={false}
                  onClick={() => handleClick(TeamsAndPartnersViewPort.pureRef)}
                >
                  Partners
                </StyledLink>
                <StyledLink
                  active={false}
                  onClick={() => handleClick(SolutionsViewPort.pureRef)}
                >
                  Solution
                </StyledLink>

                <StyledLink
                  active={false}
                  onClick={() => {
                    setIsOpenMobileNav(false);
                    window.open(
                      "https://docs.dgg.network/introduction/whitepaper"
                    );
                  }}
                >
                  Whitepaper
                </StyledLink>

                <MenuItem>
                  <NavDropDown
                    title={"Products"}
                    link={""}
                    options={[
                      {
                        link: process.env.REACT_APP_GAME_GUIDE_URL || "",
                        label: "DGG Game Platform",
                        external: true,
                        target: "_blank",
                      },
                      {
                        link:
                          process.env.REACT_APP_MARKETPLACE_AGGREGATOR_URL ||
                          "",
                        label: "Marketplace Aggregator",
                        external: true,
                        // target: "_blank",
                        commingsoon: true,
                      },
                    ]}
                    toggle={() => setIsOpenMobileNav(false)}
                  >
                    <WalletWrapper>
                      <Text
                        fontSize={16}
                        color={theme.text}
                        style={{
                          cursor: "default",
                        }}
                        fontWeight={700}
                      >
                        DGG Wallet
                      </Text>
                      <StoreWrapperMobile
                        href={process.env.REACT_APP_DGG_WALLET_APPLE_STORE_URL}
                        target="_blank"
                      >
                        <StoreImageMobile
                          src={AppleStore}
                          alt="Apple Store"
                        ></StoreImageMobile>
                      </StoreWrapperMobile>
                      <StoreWrapperMobile
                        href={process.env.REACT_APP_DGG_WALLET_GOOGLE_PLAY_URL}
                        target="_blank"
                      >
                        <StoreImageMobile
                          src={GooglePlay}
                          alt="Google Play"
                        ></StoreImageMobile>
                      </StoreWrapperMobile>
                    </WalletWrapper>
                  </NavDropDown>
                </MenuItem>
              </Flex>
            </Wrapper>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
}

const StoreWrapperMobile = styled.a`
  padding-bottom: 8px;
`;

const StoreImageMobile = styled(Image)`
  border: 1px solid ${({ theme }) => rgba(theme.primary, 0.6)};
  border-radius: 8px;
  width: 60%;
`;

const Wrapper = styled.div`
  padding: 20px 16px;
`;

const StyledLink = styled.div<{ active: boolean }>`
  border-bottom: ${({ theme, active }) =>
    active ? `2px solid ${theme.primary}` : "2px solid transparent"};
  cursor: pointer;
`;

const MenuItem = styled.div`
  flex: 1;
  padding: 0.75rem 0;
  text-decoration: none;
  display: flex;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  color: ${({ theme }) => theme.text};
  font-size: 15px;

  svg {
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }

  a {
    color: ${({ theme }) => theme.text};
    display: flex;
    align-items: center;
    :hover {
      text-decoration: none;
      color: ${({ theme }) => theme.primary};
    }
  }
`;
