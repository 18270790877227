import { ReactNode, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import useTheme from "hooks/useTheme";
import React, { useRef } from "react";
import { validateEmail } from "utils/validateEmail";
import styled from "styled-components/macro";
import { toast } from "react-toastify";
import { Flex, Text } from "rebass";
import CloseDialogIcon from "components/Icons/Close";

export default function ContactModal({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if (validateEmail(userEmail)) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
  }, [userEmail]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleClose = () => {
    setIsOpen(false);
    setUserEmail("");
  };

  const theme = useTheme();

  const form = useRef<HTMLFormElement>(null as any);

  const notifySuccess = () => toast("Send Email Success", { type: "success" });
  const notifyError = () => toast("Send Email Error", { type: "error" });

  const sendEmail = (e: any) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}mail/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: form.current.user_name.value,
        email: form.current.user_email.value,
        message: form.current.message.value,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          notifySuccess();
        } else {
          notifyError();
        }
      })
      .catch((err) => {
        notifyError();
      });
    toggleClose();
  };

  return (
    <div>
      <div onClick={toggle}>
        {children}
        <AnimatePresence>
          <DialogOverlay
            style={{
              zIndex: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            isOpen={isOpen}
            onDismiss={toggleClose}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <DialogContent
                style={{
                  minWidth: 350,
                  maxWidth: 550,
                }}
              >
                <motion.div
                  style={{
                    boxShadow: "rgba(0, 255, 255, 0.7) 0px 0px 16px",
                    backgroundColor: theme.background,
                    borderRadius: 16,
                    border: `1px solid ${theme.primary}`,
                  }}
                  initial={{ y: +30 }}
                  animate={{ y: 0 }}
                >
                  <Form ref={form} onSubmit={sendEmail}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text
                        fontSize={24}
                        color={theme.primary}
                        fontWeight={600}
                      >
                        Contact Us
                      </Text>
                      <CloseDialogIcon
                        onClick={toggleClose}
                        style={{
                          cursor: "pointer",
                          width: 32,
                          height: 32,
                        }}
                      />
                    </Flex>
                    <Label>Name *</Label>
                    <Input
                      type="text"
                      name="user_name"
                      placeholder="Your Name"
                    />
                    <Label>Email *</Label>
                    <ValidInput
                      isValidate={isValidate}
                      userEmail={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      value={userEmail}
                      type="email"
                      name="user_email"
                      placeholder="yourname@gmail.com"
                    />
                    <Label>Message *</Label>
                    <Textarea name="message" placeholder="Your Message" />
                    <SubmitInput
                      submitAble={isValidate}
                      type="submit"
                      value="Send"
                    />
                  </Form>
                </motion.div>
              </DialogContent>
            </motion.div>
          </DialogOverlay>
        </AnimatePresence>
      </div>
    </div>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Label = styled.label`
  padding: 12px 12px 12px 0;
  display: inline-block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #003452;
  border-radius: 12px;
  resize: vertical;
  background-color: #010613;
  color: ${({ theme }) => theme.text};
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary};
  }
  &::placeholder {
    color: #9ca3af;
  }
`;

const ValidInput = styled(Input)<{ isValidate: boolean; userEmail: string }>`
  border: ${(props) =>
    props.isValidate
      ? "2px solid #58C27D !important"
      : props.userEmail
      ? "2px solid #FF4D4F !important"
      : "2px solid #003452"};
`;

const SubmitInput = styled(Input)<{ submitAble: boolean }>`
  background-color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.textBlack};
  border: 1px solid ${({ theme }) => theme.primary};
  cursor: ${(props) => (props.submitAble ? "pointer" : "not-allowed")};
  opacity: ${(props) => (props.submitAble ? 1 : 0.5)};
  @media screen and (max-width: 768px) {
    padding: 12px 24px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text};
  border: 1px solid #003452;
  border-radius: 12px;
  background-color: #010613;
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary};
  }
  &::placeholder {
    color: #9ca3af;
  }
`;
