import { Flex, Text, Image } from "rebass";
import styled from "styled-components//macro";

import { DropdownTextAnchor, StyledNavExternalLink } from "./NavGroup";
import NavGroup from "./NavGroup";
import useTheme from "hooks/useTheme";
import AppleStore from "assets/svg/applestore.svg";
import GooglePlay from "assets/svg/googleplay.svg";
import { rgba } from "polished";

const ProductWrapper = styled.span`
  display: inline-flex;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const ProductsNavGroup = () => {
  const theme = useTheme();
  return (
    <ProductWrapper>
      <NavGroup
        isActive={false}
        anchor={<DropdownTextAnchor>Products</DropdownTextAnchor>}
        dropdownContent={
          <Flex
            sx={{
              flexDirection: "column",
              gap: 10,
            }}
          >
            <StyledNavExternalLink
              active={false}
              href={process.env.REACT_APP_GAME_GUIDE_URL}
              target="_blank"
              style={{
                paddingBottom: 0,
              }}
            >
              DGG Game Platform
            </StyledNavExternalLink>
            <div>
              <StyledNavExternalLinkComingSoon
                active={false}
                // href={process.env.REACT_APP_MARKETPLACE_AGGREGATOR_URL}
                // target="_blank"
                style={{
                  cursor: "auto",
                }}
              >
                DGG Marketplace
                <Text
                  style={{
                    color: theme.primary,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  (Coming Soon)
                </Text>
              </StyledNavExternalLinkComingSoon>
            </div>

            <WalletWrapper>
              <Text
                fontSize={16}
                color={theme.text}
                padding="8px 12px 0px 0px"
                style={{
                  cursor: "default",
                }}
                fontWeight={700}
              >
                DGG Wallet
              </Text>

              <StoreWrapper
                href={process.env.REACT_APP_DGG_WALLET_APPLE_STORE_URL}
                target="_blank"
              >
                <StoreImage src={AppleStore} alt="Apple Store"></StoreImage>
              </StoreWrapper>
              <StoreWrapper
                href={process.env.REACT_APP_DGG_WALLET_GOOGLE_PLAY_URL}
                target="_blank"
              >
                <StoreImage src={GooglePlay} alt="Google Play"></StoreImage>
              </StoreWrapper>
            </WalletWrapper>
          </Flex>
        }
      />
    </ProductWrapper>
  );
};

export default ProductsNavGroup;

const StyledNavExternalLinkComingSoon = styled.div<{
  active?: boolean;
  comingsoon?: boolean;
}>`
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: ${({ comingsoon }) => (comingsoon ? "default" : "pointer")};
  text-decoration: none;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  width: fit-content;
  padding: 8px 0px;
  font-weight: 600;
`;

export const StoreWrapper = styled.a`
  cursor: pointer;
`;

export const WalletWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  padding: 0px 16px 16px 0px;
  background-color: #010613;
  border-radius: 16px;
`;

export const StoreImage = styled(Image)`
  border: 1px solid ${({ theme }) => rgba(theme.primary, 0.6)};
  border-radius: 8px;
  width: 90%;
`;
