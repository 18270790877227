import BraveZone from "assets/svg/brave_zone.svg";
import CryptoCup from "assets/svg/crypto_cup.svg";
import DcGroup from "assets/svg/dc_group.svg";
import TheAnh28 from "assets/svg/theanh_28.svg";
import SuiDao from "assets/images/suidao.webp";
import { motion } from "framer-motion";
import { ViewPort } from "hooks/useBatchRef";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components/macro";
import { SectionLabel } from "../styles";

export default function Partners({
  PartnersViewPort,
}: {
  PartnersViewPort: ViewPort;
}) {
  return (
    <>
      <div ref={PartnersViewPort.ref}></div>
      <Wrapper>
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ y: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <SectionLabel>Partners</SectionLabel>
          </motion.div>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ x: [-250, 0], opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0, y: -50 }}
          >
            <ListPartnerLayout>
              <Card href="https://theanh28.vn/" target="_blank">
                <StyledImage src={TheAnh28} alt="TheAnh28 image" />
                <PartnerLable>TheAnh28</PartnerLable>
              </Card>
              <Card href="https://dcgr.net/" target="_blank">
                <StyledImage src={DcGroup} alt="DcGroup image" />
                <PartnerLable>DC Group</PartnerLable>
              </Card>
              <Card href="https://bravezone.com/" target="_blank">
                <StyledImage src={BraveZone} alt="BraveZone image" />
                <PartnerLable>BraveZone</PartnerLable>
              </Card>
              <Card href="https://crypto-cup.co/" target="_blank">
                <StyledImage src={CryptoCup} alt="CryptoCup image" />
                <PartnerLable>CryptoCup</PartnerLable>
              </Card>
            </ListPartnerLayout>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ y: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <StyledCoreMemberLabel>
              Core Member Of Move Accelerator
            </StyledCoreMemberLabel>
          </motion.div>
          <StyledCoreMember
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ x: [-250, 0], opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0, y: -50 }}
          >
            <SuiDaoCard href="https://crypto-cup.co/" target="_blank">
              <StyledSuiDaoImage src={SuiDao} alt="Sui Dao" />
            </SuiDaoCard>
            <Flex flexDirection={"column"} style={{ gap: 34 }}>
              <Text fontSize={20} fontWeight={600}>
                We're committed to provide full supports including fundraising,
                technology, community building, token-listing, marketing and
                branding.
              </Text>
              <Text fontSize={20} fontWeight={600}>
                With the mission and vision of both, DGG Network and Move
                Accelerator will bring much more value and innovation to the
                web3.0 community, and the blockchain growth.
              </Text>
            </Flex>
          </StyledCoreMember>
        </Container>
      </Wrapper>
    </>
  );
}

const StyledCoreMemberLabel = styled(SectionLabel)`
  @media screen and (max-width: 768px) {
    line-height: 40px;
  }
`;

const StyledCoreMember = styled(motion.div)`
  display: flex;
  gap: 86px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSuiDaoImage = styled.img`
  max-width: 180px;
  background-color: #1a323c00;
  border-radius: 50%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 255, 255, 0.7) 0px 0px 32px;
`;

const StyledImage = styled(Image)`
  @media screen and (max-width: 768px) {
    height: 100px !important;
  }
`;

const Card = styled.a`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background2};
  border-radius: 16px;
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    transform: translateY(-10px);
    img {
      transform: scale(1.1);
      transition-duration: 0.6s;
    }
  }
  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const SuiDaoCard = styled(Card)`
  width: fit-content;
  padding: 36px 36px;
`;

const PartnerLable = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const ListPartnerLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 80px 16px;
  background: linear-gradient(180deg, #0f181a 0%, #1c3b48 100%);
`;
