import styled, { keyframes } from "styled-components/macro";
import { Text } from "rebass";

export const updown = keyframes`
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
`;

export const Planet1Animation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 12px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const Planet2Animation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-12px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const SectionLabel = styled(Text)`
  font-size: 40px;
  font-weight: 700;
  line-height: 75px;
  color: ${({ theme }) => theme.text};
  padding: 48px 0;
`;
