import { motion } from "framer-motion";
import { ViewPort } from "hooks/useBatchRef";
import styled from "styled-components/macro";
import { SectionLabel } from "../styles";
import Achievement from "assets/svg/achievement.svg";
import { Image } from "rebass";
import Marketing from "assets/svg/marketing.svg";
import Strategy from "assets/svg/strategy.svg";

export default function Solutions({
  SolutionsViewPort,
}: {
  SolutionsViewPort: ViewPort;
}) {
  return (
    <>
      <div ref={SolutionsViewPort.ref}></div>
      <Wrapper>
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ y: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <SectionLabel>Solutions</SectionLabel>
          </motion.div>
          <ListSolutionLayout
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-delay="400"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <Card>
              <Image
                height={140}
                src={Achievement}
                alt="Achievements"
                data-aos="zoom-in-right"
                data-aos-offset="120"
                data-aos-delay="500"
              />
              <SolutionLabel>Achievement</SolutionLabel>
              <SolutionDescription>
                Supporting quality and attractive Blockchain Games in building
                product strategies and business development plans.x
              </SolutionDescription>
            </Card>
            <Card>
              <Image
                height={140}
                src={Marketing}
                alt="Marketing"
                data-aos="zoom-in-right"
                data-aos-offset="120"
                data-aos-delay="500"
              />
              <SolutionLabel>Marketing</SolutionLabel>
              <SolutionDescription>
                Directly deploy communication and marketing strategies to
                attract, and develop a large community of players globally.
              </SolutionDescription>
            </Card>
            <Card>
              <Image
                height={140}
                src={Strategy}
                alt="Strategy"
                data-aos="zoom-in-right"
                data-aos-offset="120"
                data-aos-delay="500"
              />
              <SolutionLabel>Strategy</SolutionLabel>
              <SolutionDescription>
                Helping the games achieve great value in terms of branding users
                growth and revenue.
              </SolutionDescription>
            </Card>
          </ListSolutionLayout>
        </Container>
      </Wrapper>
    </>
  );
}

const ListSolutionLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 988px) {
    grid-template-columns: 1fr;
  }
`;

const SolutionLabel = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`;

const SolutionDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background2};
  border-radius: 16px;
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    img {
      transform: scale(1.1);
      transition-duration: 0.6s;
    }
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #331944 0%, #441150 100%);
  padding: 80px 16px;
  @media (max-width: 768px) {
    padding: 40px 16px;
  }
`;
