import { ViewPort } from "hooks/useBatchRef";
import About from "./Sections/About";
import Banner from "./Sections/Banner";
import Game from "./Sections/Game";
import Partners from "./Sections/Partners";
import Products from "./Sections/Products";
import Solutions from "./Sections/Solution";

export default function Home({
  BannerViewPort,
  AboutUsViewPort,
  GameViewPort,
  TeamsAndPartnersViewPort,
  ProductsViewPort,
  SolutionsViewPort,
}: {
  BannerViewPort: ViewPort;
  AboutUsViewPort: ViewPort;
  GameViewPort: ViewPort;
  TeamsAndPartnersViewPort: ViewPort;
  ProductsViewPort: ViewPort;
  SolutionsViewPort: ViewPort;
}) {
  return (
    <>
      <Banner BannerViewPort={BannerViewPort} />
      <About AboutUsViewPort={AboutUsViewPort} />
      <Game GameViewPort={GameViewPort} />
      <Partners PartnersViewPort={TeamsAndPartnersViewPort} />
      <Solutions SolutionsViewPort={SolutionsViewPort} />
      <Products ProductsViewPort={ProductsViewPort} />
    </>
  );
}
