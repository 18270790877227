import DggLogoBig from "assets/svg/dgg_network_big.svg";
import DggNetWorkText from "assets/svg/dgg_network_text.svg";
import PlanetAbout from "assets/svg/planet_about.svg";
import { ViewPort } from "hooks/useBatchRef";
import { useScreenWidth } from "hooks/useScreenWith";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components/macro";
import { Planet1Animation, Planet2Animation } from "../styles";
export default function About({
  AboutUsViewPort,
}: {
  AboutUsViewPort: ViewPort;
}) {
  const { upToSmall } = useScreenWidth();
  return (
    <>
      <div ref={AboutUsViewPort.ref}></div>
      <Wrapper>
        <Container>
          <Content>
            <div data-aos="fade-up">
              <Flex flexDirection="column">
                <Title>What about DGG</Title>
                <StyledText>
                  <LabelText>VISION: </LabelText>
                  <DescriptionText>
                    One and pioneer ecosystem and platform for NFT gaming guilds
                    ecosystem
                  </DescriptionText>
                </StyledText>
                <StyledText>
                  <LabelText>MISSION: </LabelText>
                  <DescriptionText>
                    Approach, educate & transfer traditional gamers on board NFT
                    gamers. revolutionize gaming
                  </DescriptionText>
                </StyledText>
                <StyledText>
                  <LabelText>SECRET SOURCE: </LabelText>
                  <DescriptionText>
                    70 million users on facebook and tiktok, more than 1
                    billions reaches per month. top 1 traffice on Tiktok
                    platform in Vietnam and Southest Asia . The first MCN gains
                    40 billions view on Tiktok.
                  </DescriptionText>
                </StyledText>
              </Flex>
            </div>
            <StyledAboutImageWrapper
              data-aos="fade-down-right"
              data-aos-duration="700"
            >
              <div
                style={{
                  width: "100%", // 100% of parent
                  height: "100%", // 100% of parent
                  position: "absolute",
                  top: "-30%",
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  zIndex: 1,
                }}
              >
                <AboutLogo width="80%" src={DggNetWorkText} alt="Dgg Logo" />
              </div>
              <div
                style={{
                  width: "100%", // 100% of parent
                  height: "100%", // 100% of parent
                  position: "absolute",
                  top: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 16,
                  zIndex: 1,
                }}
              >
                <AboutLogo
                  width={upToSmall ? "65%" : "30%"}
                  src={DggLogoBig}
                  alt="DGG Logo"
                />
                <AboutText fontSize={30}>Next Generation Of Gaming</AboutText>
              </div>
            </StyledAboutImageWrapper>
            <PaletAbout data-aos="fade-down-right" src={PlanetAbout} />
          </Content>
        </Container>
      </Wrapper>
    </>
  );
}

const PaletAbout = styled(Image)`
  position: absolute;
  top: 0;
  right: -20%;
  animation: ${Planet2Animation} 3s linear infinite;
  @media screen and (max-width: 1360px) {
    display: none;
  }
`;

const Title = styled(Text)`
  font-size: 40px;
  line-height: 75px;
  font-weight: 700;
  padding: 16px 0;
  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

const StyledText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.primary};
`;

const DescriptionText = styled.span`
  color: ${({ theme }) => theme.text};
`;

const AboutLogo = styled(Image)`
  animation: ${Planet1Animation} 3s linear infinite;
`;

const AboutText = styled(Text)`
  animation: ${Planet1Animation} 3s linear infinite;
  @media screen and (max-width: 768px) {
    font-size: 22px !important;
  }
`;

const StyledAboutImageWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 16px;
  background: radial-gradient(26.25% 50% at 50% 50%, #003452 0%, #060e25 100%);
  height: 400px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 255, 255, 0.7) 0px 0px 32px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding: 20px 24px 48px;
  min-height: 100vh;
  @media screen and (max-width: 988px) {
    padding: 0px 16px 30px;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #0f181a 0%, #1c3b48 100%);
  padding: 80px 0;
`;
