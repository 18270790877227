import {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  DefaultTheme,
  css,
} from "styled-components/macro";
import { Colors } from "./styles";

export const MEDIA_WIDTHS = {
  upToExtraSmall: 576,
  upToSmall: 768,
  upToMedium: 992,
  upToLarge: 1200,
  upToXL: 1400,
  upToXXL: 1800,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = (Object.keys(MEDIA_WIDTHS) as (keyof typeof MEDIA_WIDTHS)[]).reduce(
  (accumulator, size) => {
    accumulator[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {} as { [width in keyof typeof MEDIA_WIDTHS]: typeof css }
);

function colors(): Colors {
  return {
    primary: "#00FFFF",
    secondary: "rgba(255, 255, 255, 0.11)",
    text: "#FFFFFF",
    background: "#070707",
    textBlack: "#000000",
    background2: "#1C140F",
  };
}

function theme(): DefaultTheme {
  return {
    ...colors(),
    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const themeObject = theme();

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const FixedGlobalStyle = createGlobalStyle`
  html, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-display: fallback;
  }

  @supports (font-variation-settings: normal) {
    html, input, textarea, button {
      font-family: 'Poppins', sans-serif;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  a {
    
  }

  * {
    box-sizing: border-box;
  }

  button {
    user-select: none;
  }

  html {
    font-size: 16px;
    font-variant: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  }
`;

export const ThemedGlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    /* display: none; */
  }

  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  Firefox

  html {
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.background};
    scroll-behavior: smooth;
  }

  body {
    
  }
`;
