import AOS from "aos";
import "aos/dist/aos.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from "./theme";
import "@reach/dialog/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./theme/css/index.css";
import "./theme/css/effect-carousel.scss";
import "./theme/css/main.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { ToastContainer } from "react-toastify";

AOS.init();

const ReactApp = () => {
  return (
    <StrictMode>
      <FixedGlobalStyle />
      <ToastContainer />
      <Router>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <App />
        </ThemeProvider>
      </Router>
    </StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<ReactApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
