import DggGuild from "assets/images/dgg-guild.webp";
import DggLaunchpad from "assets/images/dgg-launchpad.webp";
import DggMarketing from "assets/images/dgg-marketing.webp";
import DggScholarship from "assets/images/dgg-scholarship.webp";
import DggTournament from "assets/images/dgg-tournament.webp";
import DggId from "assets/images/dgg_id.webp";
import DggMarketplace from "assets/images/dgg_marketplace.webp";
import DggWallet from "assets/images/dgg_wallet.webp";
import { motion } from "framer-motion";
import { ViewPort } from "hooks/useBatchRef";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components/macro";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { SectionLabel } from "../styles";

import PlanetSolution from "assets/svg/planet_solution.svg";
import { ButtonPrimary } from "components/Button";
import { useState } from "react";
import { Autoplay, EffectCards, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Products({
  ProductsViewPort,
}: {
  ProductsViewPort: ViewPort;
}) {
  const [swiperSlideIndex, setSwiperSlideIndex] = useState(0);

  const project = () => {
    switch (swiperSlideIndex) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <DescriptionWrapper>
              <Title>DGG ID</Title>
              <Description>
                It is the account that you use to access all DGG services and
                make all of DGG services, applications and platforms work
                together seamlessly.
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-id",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 1:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Wallet</Title>
              <Description>
                A multi-chain crypto wallet built mainly for NFT Games.
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-wallet",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 2:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Marketplace</Title>
              <Description>
                Our marketplace gathers listings from multiple NFT Marketplaces,
                ensuring that you can always find the right NFT to purchase, and
                at the best price possible.
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-marketplace-aggregator",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );

      case 3:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Guild</Title>
              <Description>
                Our rental platform matches NFT Owners and Scholars who want to
                rent the relevant NFTs. There are more than 1,000 scholarships
                available on the first-come-first-serve basis – don’t hesitate,
                apply now!
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-game-platform",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 4:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-launchpad",
                    "_blank"
                  );
                }}
              >
                DGG Launchpad
              </Title>
              <Description>
                DGG understands the funding problem of crypto startups,
                especially game developers. Blockchain-powered fundraising is
                the best option in terms of awareness and efficiency. Contact us
                for more information at contact@dgg.network
              </Description>
              <ButtonPrimary>Read More</ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 5:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Marketing</Title>
              <Description>
                Our community of veteran KOLs will give your project a boost on
                several social media channels. We are also a team of experts
                ready to help you prepare the most powerful marketing plan and
                ensure your project’s success!
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-services#dgg-marketing",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 6:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Scholarship</Title>
              <Description>
                It provides scholarships to new players as a rewards-sharing
                model, where the guild or NFT owners acquires NFT assets and
                rents them to new players so they can start playing and earning
                in-game tokens without spending any money.
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-scholarship",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      case 7:
        return (
          <motion.div>
            <DescriptionWrapper>
              <Title>DGG Tournament</Title>
              <Description>
                It is to manage and organize NFT Game Tournaments which are a
                revolutionary new way for both professional and casual gamers to
                test their merit against one another and win great prizes and
                high value in crypto.
              </Description>
              <ButtonPrimary
                onClick={() => {
                  window.open(
                    "https://docs.dgg.network/introduction/whitepaper/dgg-products#dgg-tournament",
                    "_blank"
                  );
                }}
              >
                Read More
              </ButtonPrimary>
            </DescriptionWrapper>
          </motion.div>
        );
      default:
        return <h1>No project match</h1>;
    }
  };
  return (
    <>
      <div ref={ProductsViewPort.ref}></div>
      <Wrapper
        style={{
          minHeight: "calc(100vh + 80px)",
          position: "relative",
        }}
      >
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ y: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <SectionLabel>Products</SectionLabel>
          </motion.div>
          <Layout>
            <SidleContainer>
              <Swiper
                effect="cards"
                grabCursor={true}
                pagination={true}
                autoplay={{
                  delay: 3000,
                }}
                modules={[Pagination, Navigation, Autoplay, EffectCards]}
                className="mySwiper"
                onSlideChange={(e) => setSwiperSlideIndex(e.activeIndex)}
              >
                <SwiperSlide>
                  <SlideImage src={DggId} alt="DggId" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggWallet} alt="DggWallet" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggMarketplace} alt="DggMarketplace" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggGuild} alt="DggGuild" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggLaunchpad} alt="DggLaunchpad" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggMarketing} alt="DggMarketing" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggScholarship} alt="DggScholarship" />
                </SwiperSlide>
                <SwiperSlide>
                  <SlideImage src={DggTournament} alt="DggTournament" />
                </SwiperSlide>
              </Swiper>
            </SidleContainer>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ y: [50, 0], opacity: 1 }}
              transition={{ duration: 1 }}
            >
              {project()}
            </motion.div>
          </Layout>
        </Container>
        <Planet src={PlanetSolution} alt="Planet" />
      </Wrapper>
    </>
  );
}

const Layout = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  @media screen and (max-width: 988px) {
    flex-direction: column;
  }
`;

const DescriptionWrapper = styled(Flex)`
  flex-direction: column;
  gap: 40px;
  transition: all ease-in-out 0.3s;
`;

const SlideImage = styled(Image)`
  border-radius: 20px;
`;

const Title = styled(Text)`
  font-size: 36px;
  font-weight: 700;
`;

const Description = styled(Text)`
  font-size: 18px;
  font-weight: 400;
`;

const SidleContainer = styled.div`
  width: 30vw;
  /* height: 75vh; */

  .swiper {
    width: 100%;
    height: 100%;
    padding: 0px 16px;
    @media screen and (max-width: 988px) {
      padding: 0px 85px;
    }
  }

  .swiper-slide {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1);
    width: 200%;
  }

  .swiper-button-next {
  }

  @media screen and (max-width: 988px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 0 16px;
`;
const Wrapper = styled.div`
  width: 100%;
`;

const Planet = styled(Image)`
  position: absolute;
  bottom: -20%;
  right: 0;
  z-index: 0;
  @media screen and (max-width: 768px) {
    bottom: 0;
    width: 100%;
  }
`;
