import { useState, useEffect } from "react"

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState<number>()

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScreenWidth(window.innerWidth)
      const handleResize = () => setScreenWidth(window.innerWidth)
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [])
  const isMobile = screenWidth && screenWidth < 768
  const isBrowser = screenWidth && screenWidth >= 768
  const isTablet = screenWidth && screenWidth >= 768 && screenWidth < 1024
  const upToMedium = screenWidth && screenWidth < 992
  const upToSmall = screenWidth && screenWidth < 768


  return { screenWidth, isMobile, isBrowser, isTablet, upToMedium, upToSmall }
}
