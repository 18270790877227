import * as React from "react";

function Facebook(props: any) {
    return (
        <svg width={62} height={62} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_323_70)">
                <circle cx={31} cy={27.0005} r={17} fill="#fff" />
            </g>
            <g clipPath="url(#clip0_323_70)">
                <path
                    d="M32.998 22.993h1.096v-1.908A14.146 14.146 0 0032.498 21c-1.58 0-2.661.994-2.661 2.82v1.68h-1.744v2.134h1.744V33h2.137v-5.367h1.672l.265-2.133h-1.938v-1.47c0-.616.166-1.038 1.026-1.038z"
                    fill="#000"
                />
            </g>
            <defs>
                <filter id="filter0_d_323_70" x={0} y={0.000488281} width={62} height={62} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={7} />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_323_70" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_323_70" result="shape" />
                </filter>
                <clipPath id="clip0_323_70">
                    <path fill="#fff" transform="translate(25 21)" d="M0 0H12V12H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Facebook;
