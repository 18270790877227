import * as React from "react";

function Telegram(props: any) {
  return (
    <svg
      width={62}
      height={62}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_323_82)">
        <circle cx={31} cy={27.0005} r={17} fill="#fff" />
      </g>
      <g clipPath="url(#clip0_323_82)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 27a7 7 0 11-14 0 7 7 0 0114 0zm-6.75-1.832c-.68.283-2.04.87-4.081 1.759-.332.131-.505.26-.521.386-.027.213.24.297.603.412l.153.048c.358.116.838.252 1.088.258.227.005.48-.089.76-.28 1.906-1.288 2.89-1.938 2.952-1.952.044-.01.104-.023.145.014.041.036.037.105.033.123-.027.113-1.074 1.087-1.616 1.59-.169.158-.289.269-.313.294a6.11 6.11 0 01-.165.163c-.332.32-.58.56.014.952.286.189.515.344.743.5.25.17.498.339.82.55.081.053.16.11.236.163.29.207.55.393.872.363.187-.017.38-.193.479-.717.232-1.24.687-3.926.793-5.033a1.232 1.232 0 00-.012-.276.295.295 0 00-.1-.19.46.46 0 00-.271-.08c-.263.004-.667.145-2.611.953z"
          fill="#000"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_323_82"
          x={0}
          y={0.000488281}
          width={62}
          height={62}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={7} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_323_82"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_323_82"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_323_82">
          <path fill="#fff" transform="translate(24 20)" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Telegram;
