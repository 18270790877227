import Footer from "components/Footer";
import useBatchRef from "hooks/useBatchRef";
import { RefObject } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/Header";
import Home from "./Home";

function App() {
  const BannerViewPort = useBatchRef();
  const AboutUsViewPort = useBatchRef();
  const GameViewPort = useBatchRef();
  const TeamsAndPartnersViewPort = useBatchRef();
  const ProductsViewPort = useBatchRef();
  const SolutionsViewPort = useBatchRef();

  const scrollToSection = (elementRef: RefObject<HTMLDivElement | null>) => {
    window.scrollTo({
      top: elementRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <AppWrapper>
      <Header
        BannerViewPort={BannerViewPort}
        AboutUsViewPort={AboutUsViewPort}
        GameViewPort={GameViewPort}
        TeamsAndPartnersViewPort={TeamsAndPartnersViewPort}
        ProductsViewPort={ProductsViewPort}
        SolutionsViewPort={SolutionsViewPort}
        scrollToSection={scrollToSection}
      />

      <BodyWrapper>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                BannerViewPort={BannerViewPort}
                AboutUsViewPort={AboutUsViewPort}
                GameViewPort={GameViewPort}
                TeamsAndPartnersViewPort={TeamsAndPartnersViewPort}
                SolutionsViewPort={SolutionsViewPort}
                ProductsViewPort={ProductsViewPort}
              />
            }
          />
          <Route
            path="*"
            element={
              <Home
                BannerViewPort={BannerViewPort}
                AboutUsViewPort={AboutUsViewPort}
                GameViewPort={GameViewPort}
                TeamsAndPartnersViewPort={TeamsAndPartnersViewPort}
                ProductsViewPort={ProductsViewPort}
                SolutionsViewPort={SolutionsViewPort}
              />
            }
          />
        </Routes>
        <Footer
          BannerViewPort={BannerViewPort}
          AboutUsViewPort={AboutUsViewPort}
          GameViewPort={GameViewPort}
          TeamsAndPartnersViewPort={TeamsAndPartnersViewPort}
          ProductsViewPort={ProductsViewPort}
          SolutionsViewPort={SolutionsViewPort}
          scrollToSection={scrollToSection}
        />
      </BodyWrapper>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const BodyWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 148px);
  flex: 1;
`;
