import * as React from "react";

function Twitter(props: any) {
    return (
        <svg width={62} height={62} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_323_75)">
                <circle cx={31} cy={27.0005} r={17} fill="#fff" />
            </g>
            <g clipPath="url(#clip0_323_75)">
                <path
                    d="M37 23.47a5.56 5.56 0 01-1.536.42 2.65 2.65 0 001.173-1.473 5.325 5.325 0 01-1.69.646 2.664 2.664 0 00-4.61 1.823c0 .21.018.413.061.607a7.544 7.544 0 01-5.493-2.788 2.669 2.669 0 00.819 3.562 2.632 2.632 0 01-1.204-.328v.03c0 1.294.924 2.37 2.135 2.618a2.66 2.66 0 01-.699.088c-.17 0-.342-.01-.504-.046a2.69 2.69 0 002.49 1.857 5.355 5.355 0 01-3.304 1.136c-.219 0-.428-.01-.638-.036a7.502 7.502 0 004.088 1.196c4.905 0 7.586-4.063 7.586-7.584 0-.118-.004-.232-.01-.345A5.317 5.317 0 0037 23.47z"
                    fill="#000"
                />
            </g>
            <defs>
                <filter id="filter0_d_323_75" x={0} y={0.000488281} width={62} height={62} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={7} />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_323_75" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_323_75" result="shape" />
                </filter>
                <clipPath id="clip0_323_75">
                    <path fill="#fff" transform="translate(24 21)" d="M0 0H13V13H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Twitter;
